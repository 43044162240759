<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed  -mt-7 md:-mt-5 -mx-3 sm:-mx-8 px-3 sm:px-8 md:pt-10 mb-12"
  >
    <div class="h-full flex items-center">
      <!-- BEGIN: Logo -->
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <div
        class="-intro-x breadcrumb mt-10 lg:mt-0"
        :class="{
          'ml-auto': isRTL,
          'mr-auto': !isRTL
        }"
      >
        <a class="cursor-pointer dark:text-theme-30" @click="this.$router.push('/')">{{ i18n('breadcrumbs.application') }}</a>
        <ChevronRightIcon class="breadcrumb__icon" v-if="!isRTL" />
        <ChevronLeftIcon class="breadcrumb__icon" v-else />
        <a class="breadcrumb--active dark:text-gray-400">{{ i18n(`menu.${pageTitle}`) }}</a>
      </div>
      <!-- END: Breadcrumb -->

      <div class="mt-10 lg:mt-0 flex items-center hidden md:flex">
        <!-- <i18nFlags class="i18nFlags" /> -->
        <app-toggle-language></app-toggle-language>
      </div>
      <!-- BEGIN: Search -->
      <!-- <div class="intro-x relative mr-3 sm:mr-6">
        <div class="dropdown sm:hidden mt-10 lg:mt-0">
          <button class="dropdown-toggle " aria-expanded="false">
            <SearchIcon />
          </button>
          <div class="dropdown-menu">
            <div class="dropdown-menu__content searchDropdown box dark:bg-dark-1 p-3">
              <h2>{{ i18n('topbar.search.pages') }}</h2>
              <div class="flex mt-5">
                <div
                  class="w-10 h-10 bg-theme-29 text-theme-10 flex items-center justify-center rounded-full"
                  @click="goToUsers()"
                >
                  <UsersIcon />
                </div>
                <div class="ml-3 mr-3 mt-2">
                  {{ i18n('topbar.search.users') }}
                </div>
              </div>
              <div class="flex mt-2">
                <div
                  class="w-10 h-10 bg-theme-30 text-theme-24 flex items-center justify-center rounded-full"
                  @click="goToSeller()"
                >
                  <UsersIcon />
                </div>
                <div class="ml-3 mr-3 mt-2">
                  {{ i18n('topbar.search.sellers') }}
                </div>
              </div>
              <div class="flex mt-2">
                <div
                  class="w-10 h-10 bg-theme-31 text-theme-26 flex items-center justify-center rounded-full"
                  @click="goToWallet()"
                >
                  <DollarSignIcon class="" />
                </div>
                <div class="ml-3 mr-3 mt-2">
                  {{ i18n('topbar.search.wallet') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="search hidden sm:block">
          <input
            type="text"
            class="search__input form-control dark:bg-dark-1 border-transparent placeholder-theme-8 dark:text-white"
            @focus="showSearchDropdown"
            @blur="hideSearchDropdown"
          />
          <SearchIcon class="search__icon dark:text-gray-300" />
          <div
            id="search-popup"
            class="search-result"
            :class="{ show: searchDropdown }"
          >
            <div class="search-result__content">
              <div class="search-result__content__title">
                {{ i18n('topbar.search.pages') }}
              </div>
              <div class="mb-5">
                <div
                  class="flex items-center cursor-pointer"
                  @click="goToUsers()"
                >
                  <div
                    class="w-8 h-8 bg-theme-29 text-theme-10 flex items-center justify-center rounded-full"
                  >
                    <UsersIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3 mr-3">{{ i18n('topbar.search.users') }}</div>
                </div>
                <div
                  class="flex items-center mt-2 cursor-pointer"
                  @click="goToSeller()"
                >
                  <div
                    class="w-8 h-8 bg-theme-30 text-theme-24 flex items-center justify-center rounded-full"
                  >
                    <UsersIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3 mr-3">
                    {{ i18n('topbar.search.sellers') }}
                  </div>
                </div>
                <div
                  class="flex items-center mt-2 cursor-pointer"
                  @click="goToWallet()"
                >
                  <div
                    class="w-8 h-8 bg-theme-31 text-theme-26 flex items-center justify-center rounded-full"
                  >
                    <DollarSignIcon class="w-4 h-4" />
                  </div>
                  <div class="ml-3 mr-3">
                    {{ i18n('topbar.search.wallet') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Search -->

      <!-- BEGIN: Notifications -->
      <!-- <div class="dropdown">
        <button class="dropdown-toggle " aria-expanded="false">
          <div
            class="dropdown-toggle notification notification--bullet cursor-pointer mr-4 mt-10 lg:mt-0"
            role="button"
            aria-expanded="false"
          >
            <BellIcon class="notification__icon dark:text-gray-300" />
          </div>
        </button>
        <div class="dropdown-menu">
          <div
            class="dropdown-menu__content notificationDropDown box dark:bg-dark-1 p-3"
          >
            <div class="notification-content__title mb-2">
              {{ i18n('topbar.notifications.title') }}
            </div>
            <div
              v-for="(faker, fakerKey) in $_.take($f(), 5)"
              :key="fakerKey"
              class="cursor-pointer relative flex items-center"
              :class="{ 'mt-5': fakerKey }"
            >
              <div class="w-12 h-12 flex-none image-fit mr-1">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  :src="require(`@/assets/images/${faker.photos[0]}`)"
                />
                <div
                  class="w-3 h-3 bg-theme-10 absolute right-0 bottom-0 rounded-full border-2 border-white"
                ></div>
              </div>
              <div class="ml-2 overflow-hidden">
                <div class="flex items-center">
                  <a
                    href="javascript:;"
                    class="font-medium truncate "
                    :class="isRTL ? 'mr-2 ml-10' : 'mr-5'"
                  >
                    Ahmed Mohamed
                  </a>
                  <div class="text-xs text-gray-500 ml-auto whitespace-nowrap">
                    {{ i18n('topbar.notifications.time') }}
                  </div>
                </div>
                <div
                  class="w-full truncate text-gray-600 dark:text-gray-400 mt-0.5 mr-2"
                >
                  {{ i18n('topbar.notifications.description') }}
                </div>
              </div>
            </div>
            <div
              class="flex btn justify-center items-center bg-theme-31 text-white mt-5 mr-5 ml-5"
              @click="goToNotifications()"
            >
              {{ i18n('topbar.notifications.viewNotifications') }}
            </div>
          </div>
        </div>
      </div> -->
      <!-- END: Notifications -->

      <!-- BEGIN: Account Menu -->
      <div class="dropdown">
        <button class="dropdown-toggle " aria-expanded="false">
          <div
            class="dropdown-toggle w-8 h-8 rounded-full overflow-hidden shadow-lg image-fit zoom-in scale-110 mr-4 ml-4 mt-10 lg:mt-0"
            role="button"
            aria-expanded="false"
            @click="showAccountDropDown = true"
          >
            <img :src="currentUserAvatar" style="border-radius: 50%; height: 100%;" v-if="currentUserAvatar" />
            <img :src="require(`@/assets/images/${$f()[9].photos[0]}`)" alt="Icewall Tailwind HTML Admin Template" v-else />
          </div>
        </button>
        <div class="dropdown-menu" v-if="showAccountDropDown">
          <div class="dropdown-menu__content profileDropDown box dark:bg-dark-6">
            <div class="p-4">
              <div class="font-medium">{{ currentUserNameOrEmailPrefix }}</div>
            </div>
            <div class="p-2">
              <div
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-31 rounded-md cursor-pointer"
                @click="goToProfile()"
              >
                <UserIcon class="w-4 h-4" :class="isRTL ? 'ml-2' : 'mr-2'" />
                {{ i18n('topbar.profile.profile') }}
              </div>
            </div>
            <div class="p-2">
              <div
                href=""
                class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-31 rounded-md cursor-pointer dropdown-toggle"
                @click="doLogout()"
              >
                <ToggleRightIcon
                  class="w-4 h-4"
                  :class="isRTL ? 'ml-2' : 'mr-2'"
                />
                {{ i18n('topbar.profile.logout') }}
              </div>
            </div>

            <div class="border-t border-gray-200 py-2 flex justify-center md:hidden">
              <app-toggle-language></app-toggle-language>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import VueLottiePlayer from 'vue-lottie-player'

export default defineComponent({
  computed: {
    ...mapGetters({
      currentUserNameOrEmailPrefix: 'auth/currentUserNameOrEmailPrefix',
      currentUserAvatar: 'auth/currentUserAvatar',
      currentUser: 'auth/currentUser',

      // newNotifications: 'notification/list/newNotifications',
      // unreadNotifications: 'notification/list/unreadNotificationsToHeader',
      // latestNotifications: 'notification/list/latestNotifications',

      isMobile: 'layout/isMobile',
      is_screen_xs: 'layout/is_screen_xs',
      is_screen_sm: 'layout/is_screen_sm',
      is_screen_md: 'layout/is_screen_md',
      is_screen_lg: 'layout/is_screen_lg',
      is_labtop: 'layout/is_labtop'
      // currentLanguageCode: 'layout/currentLanguageCode',
    }),
    isRTL() {
      return this.$i18n.locale == 'ar'
    },
    isMobile() {
      return screen.width <= 425
    },
    pageTitle() {
      var currentPage = this.$route.name
      // console.log('Current Page =', currentPage)
      if (currentPage.includes('home')) return 'Dashboard'
      if (currentPage.includes('admin')) return 'Admins'
      if (currentPage.includes('user')) return 'Users'
      if (currentPage.includes('event')) return 'Events'
      if (currentPage.includes('setting')) return 'Settings'
      if (currentPage.includes('profile')) return 'Profile'
      if (currentPage.includes('change-password')) return 'Change Password'
      return ''
    }
  },
  setup() {
    const searchDropdown = ref(false)
    const showAccountDropDown = ref(false)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }

    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      showAccountDropDown,
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown
    }
  },
  methods: {
    ...mapActions({
      toggleMenu: 'layout/toggleMenu',
      doSignout: 'auth/doSignout'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    goToProfile() {
      console.log('profile')
      this.$router.push('/profile')
    },
    doLogout() {
      this.showAccountDropDown = false
      this.doSignout()
      // this.$router.push('/auth/login')
    },
    goToUsers() {
      this.$router.push('/users')
    },
    goToSeller() {
      this.$router.push('/seller')
    },
    goToWallet() {
      this.$router.push('/wallet')
    },
    goToNotifications() {
      this.$router.push('/notifications')
    }
    // navigate() {
    //   var currentPage = this.$route.name
    //   console.log(currentPage)
    //   if (currentPage.includes('dashboard')) this.$router.push('')
    //   if (currentPage.includes('product')) this.$router.push('')
    //   if (currentPage.includes('order')) this.$router.push('')
    //   if (currentPage.includes('user')) this.$router.push('')
    //   if (currentPage.includes('seller')) this.$router.push('')
    //   if (currentPage.includes('category')) return 'category'
    //   if (currentPage.includes('subcategory')) return 'subcategory'
    //   if (currentPage.includes('settings')) return 'settings'
    //   if (currentPage.includes('complaint')) return 'complaint'
    //   if (currentPage.includes('wallet')) return 'wallet'
    //   if (currentPage.includes('notification')) return 'notification'
    //   if (currentPage.includes('offer')) return 'offer'
    //   if (currentPage.includes('slider')) return 'slider'
    //   if (currentPage.includes('report')) return 'report'
    //   if (currentPage.includes('profile')) return 'profile'
    // }
  }
})
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&display=swap');
.i18nFlags {
  @media only screen and (max-width: 425px) {
    display: none;
  }
}
.searchDropdown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .searchDropdown {
  right: unset !important;
  left: 0 !important;
}

.notificationDropDown {
  width: 300px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .notificationDropDown {
  right: unset !important;
  left: 0 !important;
}

.profileDropDown {
  width: 200px !important;
  position: absolute !important;
  right: 0 !important;
}
[dir='rtl'] .profileDropDown {
  right: unset !important;
  left: -20px !important;
}
</style>
