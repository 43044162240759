<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />
    <!-- <TopBar /> -->
    <div class="flex">
      <!-- BEGIN: Side Menu -->
      <nav class="side-nav">
        <!-- BEGIN: Logo -->
        <div>
          <img src="/images/aracia-logo-white.png" alt="ARACIA" class="w-100" />
        </div>
        <!-- END: Logo -->
        <div class="side-nav__devider my-6"></div>

        <ul>
          <!-- BEGIN: First Child -->
          <template v-for="(menu, menuKey) in formattedMenu">
            <li v-if="menu == 'devider'" :key="menu + menuKey" class="side-nav__devider my-6"></li>
            <li v-else :key="menuKey" >
              <SideMenuTooltip tag="a" :content="menu.title" v-if="hasPermissionToRead(menu.permission)"
                :href="menu.subMenu ? 'javascript:;' : router.resolve({ name: menu.pageName }).path"
                class="side-menu"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event)"
              >
                <div class="side-menu__icon">
                  <component :is="menu.icon" />
                </div>
                <div class="side-menu__title mr-2">
                  {{ i18n(`menu.${menu.title}`) }}
                  <!-- <span class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5" v-if="menu.title == 'Orders' && ordersBadge > 0">{{ ordersBadge }}</span>
                  <span class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5" v-if="menu.title == 'Products' && productsBadge > 0">{{ productsBadge }}</span>
                  <span class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5" v-if="menu.title == 'Payments' && requestsBadge > 0">{{ requestsBadge }}</span>
                  <span class="bg-theme-24 text-white font-bold rounded-full mx-2 px-1.5" v-if="menu.title == 'Complaints' && newComplaintsCount > 0">{{ newComplaintsCount }}</span> -->
                  <div v-if="menu.subMenu" class="side-menu__sub-icon" :class="{ 'transform rotate-180': menu.activeDropdown }">
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li v-for="(subMenu, subMenuKey) in menu.subMenu" :key="subMenuKey">
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu"
                      :class="{ 'side-menu--active': subMenu.active }"
                      @click="linkTo(subMenu, router, $event)"
                    >
                      <div class="side-menu__icon">
                        <ActivityIcon />
                      </div>
                      <div class="side-menu__title">
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(lastSubMenu,
                          lastSubMenuKey) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu"
                            :class="{ 'side-menu--active': lastSubMenu.active }"
                            @click="linkTo(lastSubMenu, router, $event)"
                          >
                            <div class="side-menu__icon">
                              <ZapIcon />
                            </div>
                            <div class="side-menu__title">
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>
          </template>
          <!-- END: First Child -->
        </ul>
      </nav>
      <!-- END: Side Menu -->

      <!-- BEGIN: Content -->
      <div class="content">
        <TopBar />
        <router-view />
      </div>
      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
// import DarkModeSwitcher from '@/components/dark-mode-switcher/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { mapActions, mapGetters } from 'vuex'
import PermissionChecker from '@/security/permission-checker'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    // DarkModeSwitcher,
    SideMenuTooltip
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const darkMode = computed(() => store.state.main.darkMode)
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .removeClass('login-rtl')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      darkMode,
      router,
      linkTo,
      enter,
      leave
    }
  },
  computed: {
    ...mapGetters({
      // ordersBadge: 'realtime/ordersBadge',
      // productsBadge: 'realtime/productsBadge',
      // requestsBadge: 'realtime/requestsBadge',
      // listenerLoading: 'realtime/loading',
      // newComplaintsCount: 'complaintStore/newComplaintsCount',
      currentUser: 'auth/currentUser'
    })
  },
  async created() {
    this.doRunCurrentUserChangedListener()
    // this.doRunListenerOnNewOrders()
    // this.doRunListenerOnNewProducts()
    // this.doRunListenerOnNewRequests()
    // this.doRunListenerOnNewComplaints()
  },
  methods: {
    ...mapActions({
      // doRunListenerOnNewOrders: 'realtime/doRunListenerOnNewOrders',
      // doRunListenerOnNewProducts: 'realtime/doRunListenerOnNewProducts',
      // doRunListenerOnNewRequests: 'realtime/doRunListenerOnNewRequests',
      // doRunListenerOnNewComplaints: 'complaintStore/doRunListenerOnNewComplaints',
      doRunCurrentUserChangedListener: 'auth/doRunCurrentUserChangedListener'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    hasPermissionToRead(permission) {
      return new PermissionChecker(this.currentUser).match(permission)
    }
  }
})
</script>

<style lang="scss">
.runProf {
  position: absolute;
  // margin-bottom: auto !important;
  bottom: 5%;
  left: 4%;
  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 1%;
    // display: none;
  }
}

[dir='rtl'] .runProf {
  position: absolute;
  bottom: 5%;
  left: 91% !important;
  @media only screen and (max-width: 1024px) {
    width: 80px;
    left: 90% !important;
    // display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 80px;
    left: 87% !important;
    // display: none;
  }
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:before {
  content: '';
  transform: rotate(180deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav > ul > li > .side-menu.side-menu--active:after {
  content: '';
  transform: rotate(270deg) scale(1.04) !important;
  right: unset !important;
  left: 0 !important;
}
[dir='rtl']
  .side-nav
  > ul
  > li
  > .side-menu.side-menu--active
  .side-menu__icon:before {
  content: '';
  right: unset !important;
  left: 0 !important;
}

[dir='rtl'] .side-nav .side-menu {
  padding-right: 1.25rem !important;
}
</style>
<!-- #64697b -->
